.mainContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #3C3C3B;
    color: white;
}

.gear-icon {
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .gear-icon {
        animation: gear-icon-spin infinite 15s linear;
    }
}

@keyframes gear-icon-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.name-container {
    font-weight: bold;
    font-size: 25pt;
    padding: 15pt 5pt 5pt 5pt;
}

.info-container {
    font-size: 15pt;
    padding: 10pt 5pt 5pt 5pt;
}

.enter-container {
    font-size: 20pt;
    padding: 10pt 5pt 5pt 5pt;
}
